import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faFaceSadTear } from "@fortawesome/free-regular-svg-icons";

import NavBar from "../components/common/navBar";
import Logo from "../components/common/logo";

import INFO from "../data/user";

import "./styles/404.css";
import { postData } from "../fetchData";

const SomethingWentWrong = (props) => {
	const { error } = props;

	useEffect(() => {
		document.title = `404 | ${INFO.main.title}`;
    
		const slackPostError = async () => {
			try {
				await postData("homepage/frontend-error", error);
			} catch (err) {
				console.log('Something Went Wrong')
			}
		};

		slackPostError()

	}, [error]);

	return (
		<React.Fragment>
			<div className="not-found page-content">
				<NavBar />
				<div className="content-wrapper">
					<div className="notfound-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="notfound-container">
						<div className="notfound-message">
							<div className="notfound-title">
								Oops! <FontAwesomeIcon icon={faFaceSadTear} />
							</div>
							<div className="not-found-message">
								Something Went Wrong.
							</div>
							<a href="/" className="not-found-link">
								Try Again
							</a>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SomethingWentWrong;