import React, { useState } from 'react';
import "./styles/contactForm.css";
import ReCAPTCHA from 'react-google-recaptcha';
import SomethingWentWrong from '../../pages/error';
import ReactLoading from 'react-loading';
import { postData } from '../../fetchData';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        text: '',
    });

    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [captchaValue, setCaptchaValue] = useState(null);
    const [formErrors, setFormErrors] = useState('');
    const [formStatus, setFormStatus] = useState('');

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleCaptcha = (value) => {
        setCaptchaValue(value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!captchaValue) {
            setFormErrors('Please complete the captcha.');
            return;
        }

        const submitData = async () => {
            setLoading(true);
            try {
                await postData("contact-form", formData);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
                setFormData({
                    name: '',
                    email: '',
                    text: '',
                })
            }
        };
        submitData();
        setFormErrors('');
        setFormStatus('Form submitted successfully!');
    };

    if (isLoading) {
        return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ReactLoading type={'bars'} color={'#14b8a6'} height={667} width={375} />
        </div>);
    }

    if (error) {
        return <SomethingWentWrong error={error} />
    }

    return (
        <div className="form-wrapper">
            {formErrors && <div className="alert alert-danger">{formErrors}</div>}
            {formStatus && <div className="alert alert-success">{formStatus}</div>}

            <h4>Leave a message</h4>
            <form onSubmit={handleSubmit} id="myForm">
                <div className="form-group">
                    <label>Name</label>
                    <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <div className="form-text">What should I call you?</div>
                </div>

                <div className="form-group">
                    <label>Email</label>
                    <input
                        type="email"
                        name="email"
                        className="form-control"
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <div className="form-text">Provide an email where I can reach back to you.</div>
                </div>

                <div className="form-group">
                    <label>Your Message</label>
                    <textarea
                        className="form-control"
                        rows="10"
                        name="text"
                        value={formData.text}
                        onChange={handleChange}
                        required
                    />
                    <div className="form-text">Leave your message here.</div>
                </div>

                <div className="form-group">
                    <ReCAPTCHA
                        sitekey="6LfDou4fAAAAAEjw-c0c7p7sZN-PrthUUvwd0uMX"
                        onChange={handleCaptcha}
                    />
                </div>

                <button type="submit" className="submit-button">
                    Submit
                </button>
            </form>
        </div>
    );
};

export default ContactForm;
