import localforage from "localforage";

export const API_URL = process.env.REACT_APP_API_URL;

function encodeToken(token) {
	const utcTime = Math.floor(Date.now() / 1000);
	const encodedToken = btoa(`${token}:${utcTime}`);
	return encodedToken;
}
export const getAuthorizationHeader = () => {
	const token = process.env.REACT_APP_SECRET_KEY;
	const encodedToken = encodeToken(token);
	return `Bearer ${encodedToken}`;
};

export const fetchData = async (endpoint) => {
	const last_update_time = window.cacheTime;
	const url = `${API_URL}/api/${endpoint}`;

	try {
		const cachedData = await localforage.getItem(url);
		if (cachedData) {
			const { data, timestamp } = cachedData;
			if (
				last_update_time &&
				new Date(last_update_time).getTime() > timestamp
			) {
				try {
					await localforage.removeItem(url);
				} catch (error) {}
			} else {
				return data;
			}
		}
	} catch (error) {}

	const response = await fetch(url, {
		method: "GET", // or 'POST', etc.
		headers: {
			Authorization: getAuthorizationHeader(),
		},
	});
	if (!response.ok) {
		throw new Error("Network response was not ok");
	}
	const data = await response.json();
	const timestamp = new Date().getTime();
	try {
		await localforage.setItem(url, { data, timestamp });
	} catch (error) {}

	return data;
};

export const postData = async (endpoint, newData) => {
	const url = `${API_URL}/api/${endpoint}`;
	const response = await fetch(url, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: getAuthorizationHeader(),
		},
		body: JSON.stringify(newData),
	});
	return response;
};
