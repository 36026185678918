import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/contact.css";
import ContactForm from "../components/contactForm/contactForm";

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "contact");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Contact | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="contact" />
				<div className="content-wrapper">
					<div className="contact-logo-container">
						<div className="contact-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="contact-container">
						<div className="title contact-title">
							Let's Connect: Ways to Reach Out
						</div>

						<div className="subtitle contact-subtitle">
							Thank you for your interest in connecting with me. I value your feedback, questions, and suggestions. For specific inquiries or comments, please email me directly or leave a message in the form. I aim to respond within 24 hours, though it may take longer during busy times. Alternatively, you can use the contact form on my website by filling out the required fields, and I'll reply as soon as possible. If you prefer social media, you can find me there as well. I regularly post updates and interact with my followers, so feel free to reach out. Thanks again for your interest, and I look forward to hearing from you!{" "}
						</div>
					</div>

					<div className="card">
						<div className="side-by-side-container">
							<div className="socials-container">
								<div className="contact-socials">
									<Socials />
								</div>
							</div>

							<div className="contact-form-container">
								<ContactForm />
							</div>
						</div>
					</div>

				</div>
			</div>
			<div className="page-footer">
				<Footer />
			</div>
		</React.Fragment>
	);
};

export default Contact;
