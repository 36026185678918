import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Works from "../components/about/works";
import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/about.css";
import LoadingUI from "../components/common/loading";
import SomethingWentWrong from "./error";
import { API_URL, fetchData } from "../fetchData";
import Education from "../components/about/education";
import Skills from "../components/about/skills";
import Awards from "../components/about/awards";
import Research from "../components/about/research";
import { Link } from "react-router-dom";

const About = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "about");
	const [aboutData, setAboutData] = useState([]);
	const [resumeLink, setResumeLink] = useState([]);

	const [isLoading, setLoading] = useState(true);
	const [error, setError] = useState(null);


	useEffect(() => {
		const fetchDataAsync = async () => {
			setLoading(true);
			try {
				const [aboutData, resumeData] = await Promise.all([
					fetchData("resume/view"),
					fetchData("resume/pdf")
				]);

				setAboutData(aboutData);
				setResumeLink(resumeData.url);
			} catch (error) {
				setError(error);
			} finally {
				setLoading(false);
			}
		};

		fetchDataAsync();
	}, []);


	if (isLoading) {
		return <LoadingUI active='about' />;
	}

	if (error) {
		return <SomethingWentWrong error={error} />
	}


	return (
		<React.Fragment>
			<Helmet>
				<title>{`About | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="about" />
				<div className="content-wrapper">
					<div className="about-logo-container">
						<div className="about-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="about-container">

						<div className="about-first-area">
							<div className="about-first-area-left-side">
								<div className="title about-title">
									{INFO.about.title}
								</div>

								<div
									className="subtitle about-subtitle"
								>{INFO.about.description}</div>

								<div
									className="subtitle about-subtitle"
								>
									<Link to={resumeLink} style={{ textDecoration: 'none', color: 'inherit' }} target="_blank" rel="noopener noreferrer">
										<FontAwesomeIcon
											icon={faFileDownload}
											className="homepage-social-icon"
										/>
										Download the PDF Resume.
									</Link>
								</div>

							</div>

							<div className="about-first-area-right-side">
								<div className="about-image-container">
									<div className="about-image-wrapper">
										<img
											src={
												`${API_URL}${aboutData.image.side_pic}`
											}
											alt="about"
											className="about-image"
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="about-after-title">
							<Education data={aboutData.education} />
							<Works data={aboutData.workExp} />
							<Skills data={aboutData.skills} />
							<Awards data={aboutData.awards} />
							<Research data={aboutData.research} />
						</div>

					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default About;
